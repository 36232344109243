import React from 'react';
import { Link } from 'gatsby';
import homeicon from '../images/home.svg';
import rarr from '../images/rarr.svg';
import larr from '../images/larr.svg';
import './style.scss';
import colorhero from '../images/colorphero.jpg';
import download from '../images/download.svg';
import colorad from '../images/coloradial.svg';
var pageTitle = "Color";

const ColorPage = () => (
	<div>

		<section className="pagetitle brandpage colorpbg">
			<div className="container">
			<h1 className="title white capitol">{pageTitle}</h1>
			</div>
		</section>

		<section className="pbt-section-small-large section">
			<div className="container">
			<div className="columns">
				
				<div className="column pr-6">
				<h2 className="is-size-1 capitol steel">Classic Red &amp; Friends</h2>
				<p className="mb-6">It goes by many names: Candy apple. Fire Engine. Blood orange. Pantone&reg; 186. But to us and the local community it will always be Baker Barrios Red&trade;. Now she’s bringing some new friends along with their own complimentary personalities, ready for the next leap.</p>
				<a className="button is-primary" target="_blank" href={'/BBA_Colors.zip'}><span className="mr-5">Download Color Palette</span> <span className="icon is-small"><img src={download}/></span> </a>
				</div>

				<div className="column  is-justify-content-center  is-align-items-center is-flex">
					<div className="logostage ">
						<img src={colorhero}/>
					</div>
				</div>

			</div>
			</div>
		</section>

		<div className="container">
			<div className="columns">
				<div className="column">
		<div className="colorgradbar"></div>
			</div>
			</div>
		</div>

		<section className="colorblock pbt-section">

		<div className="container">
			<div className="color-container is-flex">

				<div className="color bbaredbg white">
					<strong>Baker Barrios Red</strong><br/>
					Pantone 186 U/C<br/>
					HEX  #CE202F<br/>
					RGB  206  32  47
				</div>

				<div className="colorgroup colorgroupvert is-flex is-flex-direction-column">

					<div className="color steelbg white">
						<strong>Steel</strong><br/>
						HEX  #3D405B<br/>
						RGB  61  64  91
					</div>

					<div className="colorgroup colorgrouphorz is-flex">

						<div className="color oldgoldbg">
						<strong>Old Gold</strong><br/>
						HEX  #E4B363<br/>
						RGB  228  179  99
						</div>

						<div className="color youngvinebg">
						<strong>Young Vine</strong><br/>
						HEX  #81B29A<br/>
						RGB  129  178  154
						</div>

					</div>

				</div>

				<div className="colorgroup colorgroupvert is-flex is-flex-direction-column">

					<div className="colorgroup colorgrouphorz is-flex half-flex">
						<div className="color earthwarebg white">
						<strong>Earthware</strong><br/>
						HEX  #E07A5F<br/>
						RGB  224  122  95
						</div>
						<div className="color deepseabg white">
						<strong>Deep Sea</strong><br/>
						HEX  #19323C<br/>
						RGB  25  50  60
						</div>
					</div>

					<div className="colorgroup colorgroupgrid half-flex">
						<div className="color pastelbg">
						<strong>Pastel Gray</strong><br/>
						HEX  #C2D2BC<br/>
						RGB  204  215  197
						</div>
						<div className="color oxygenbg">
						<strong>Oxygen</strong><br/>
						HEX  #A3BFBF<br/>
						RGB  163  191  191
						</div>
						<div className="color stonebg white">
						<strong>Stone</strong><br/>
						HEX #24292B<br/>
						RGB  49  54  56
						</div>
						<div className="color patinabg p-0">
							<div className="light-border">
								<strong>Patina</strong><br/>
								HEX #F3F7F0<br/>
								RGB  243  247  240
							</div>
						</div>

					</div>

				</div>

			</div>
			</div>
		</section>

		<section className="pbt-section-small pt-0 section">
			<div className="container">
				<div className="columns">
					<div className="column is-half">
					<h2 className="is-size-1 capitol steel">The BBA Spectrum</h2>
					<p className="mb-6">Apart from our main brand color we utilize a number of supporting, complimentary colors to round out our palette. In general we prefer a restrained color palette with pops of color to draw attention and create hierarchy. This is supported by ample ‘white’ space and a clean, airy, inviting layout. However all the colors serve the brand and may be used accordingly.</p>
					</div>
				</div>
			</div>
		</section>

		<section className="whitebg pbt-section-small section">
			<div className="container">
				<div className="columns is-align-items-center">
					<div className="column">
					<img src={colorad}/>
					</div>
					<div className="column">
						<h2 className="is-size-1 capitol steel">Color Distribution</h2>
						<p>All colors in the palette are fair game for use though we prefer to emphasize some over others. Our patina, BBA Red, and steel may be used liberally while pastel gray, oxygen, and stone are great support hues. Old gold, young vine, earthware, and deep sea are great for accents and callouts when needed. In general airy, welcoming designs are the standard across communications.</p>
					</div>	
				</div>
			</div>
		</section>

		<section className="pbt-section">
			<div className="container">
				<div className="columns">
					<div className="column is-flex is-flex-direction-column is-justify-content-center">
						<div className="pr-6 pt-6 pb-6 section">
						<h2 className="is-size-1 capitol steel mb-3">Color &amp; Text</h2>
						<p>Selecting colors for text is an important part of maintaining accessibility and legibility. Only colors on the light end of the BBA palette can be used on dark backgrounds and vice versa. Always err on the side of legibility. For example: black and red, or red and blue, are notoriously difficult to read on top of one another, please be sure to choose colors that contrast well.</p>
					</div>
					</div>
					<div className="column whitebg">
						<div className="textexcolor">
						<h3 className="capitol is-size-2 steel mb-4">Headline Text</h3>
						<h4 className="is-uppercase bbared has-text-weight-bold caption mb-1">Subheader for more information</h4>
						<p className="bodysmall">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et congue pellentesque non nunc malesuada ipsum. Scelerisque bibendum aenean aliquam id vel tellus mi. Adipiscing nunc in erat augue et vestibulum mauris sit. Nibh quam ullamcorper eu nunc justo. Viverra sagittis, risus sagittis, amet vitae arcu aliquam massa. Consectetur sed in vitae platea pellentesque non lectus. Gravida felis odio ornare.</p>
					</div>
					</div>
				</div>
				<div className="columns">
				<div className="column bbaredbg white is-flex is-flex-direction-column is-justify-content-center">
				<div className="textexcolor">

						<h3 className="capitol is-size-2 white mb-4">Headline Text</h3>
						<h4 className="is-uppercase pastel has-text-weight-bold caption mb-1">Subheader for more information</h4>
						<p className="bodysmall">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et congue pellentesque non nunc malesuada ipsum. Scelerisque bibendum aenean aliquam id vel tellus mi. Adipiscing nunc in erat augue et vestibulum mauris sit. Nibh quam ullamcorper eu nunc justo. Viverra sagittis, risus sagittis, amet vitae arcu aliquam massa. Consectetur sed in vitae platea pellentesque non lectus. Gravida felis odio ornare.</p>
					</div>
					</div>
					<div className="column stonebg white is-flex is-flex-direction-column is-justify-content-center">
					<div className="textexcolor">
						<h3 className="capitol is-size-2 white mb-4">Headline Text</h3>
						<h4 className="is-uppercase oldgold has-text-weight-bold caption mb-1">Subheader for more information</h4>
						<p className="bodysmall">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et congue pellentesque non nunc malesuada ipsum. Scelerisque bibendum aenean aliquam id vel tellus mi. Adipiscing nunc in erat augue et vestibulum mauris sit. Nibh quam ullamcorper eu nunc justo. Viverra sagittis, risus sagittis, amet vitae arcu aliquam massa. Consectetur sed in vitae platea pellentesque non lectus. Gravida felis odio ornare.</p>
					</div>
					</div>
				</div>
			</div>
		</section>

		<div className='footer-nav'>
			<nav className='navbarfoot'>
				<div className='container spotgrid'>
					<Link className='prevpage' to='/logo'>
					<img src={larr} alt='Left Arrow' /> Logo 
					</Link>
					<div className="has-text-centered">
					<Link className='homebut' to='/'>
						<img src={homeicon} alt='Home' />
					</Link>
					</div>
					<Link className='nextpage' to='/typography'>
						Typography <img src={rarr} alt='Right Arrow' />
					</Link>
				</div>
			</nav>
		</div>

	</div>
);

export default ColorPage;
