import React from 'react';

import './style.scss';
import Header from './header';
import ColorPage from './colorpage';
import Footnav from './footnav'
import Footer from './footer';

const LogoLayout = ({ children }) => (
	<div>
		<Header />
		<ColorPage />
		<Footer />
	</div>
);

export default LogoLayout;
